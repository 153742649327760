import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user';
import { SharedService } from './../shared/services/shared.service';
import { StorageService } from './../shared/services/storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {
  congressId: number;
  user: User;
  showMeetingButton: boolean;

  constructor(private storageService: StorageService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    this.user = this.storageService.read(StorageService.USER_KEY);
   }

  ngOnInit(): void {
    if (!this.user) {
      this.router.navigate(['/auth/login']);
    }
    this.sharedService.setTitle('Search');
    this.showMeetingButton = this.route.snapshot.data['showMeetingButton'];
  }

}
