import { StorageService } from './../shared/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Congress } from '../shared/models/congress';
import { CongressService } from '../shared/services/congress.service';
import { Utils } from '../shared/utils';
import { SharedService } from '../shared/services/shared.service';
import { User } from '../shared/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from '../shared/services/messaging.service';
import { FireBaseKeyRequest } from '../shared/models/FireBaseKeyRequest';
import { PwaBtn } from '../shared/models/PwaBtn';
import { PwaMenu } from '../shared/models/pwamenu';
import { ConfigLandignPage } from '../shared/models/ConfigLandingPage';
import { MotPresident } from '../shared/models/MotPresident';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  busy: Subscription;
  congressId: string;
  congress: Congress = new Congress();
  configLandingPage: ConfigLandignPage = new ConfigLandignPage();
  filesUrl: string = environment.filesUrl;
  user: User;
  lang: string;
  body = new FireBaseKeyRequest();
  pwaBtns: PwaBtn[] = [];
  motPresident: MotPresident;
  pathsLogo = [];
  pwamenus: PwaMenu[];
  redirectto: any;
  truncatedContent: string | null = null;
  expanded: boolean = false;
  truncatedMot: string | null = null;
  showExpandButton: boolean = false;

  constructor(private congressService: CongressService,
    private storageService: StorageService,
    private sharedService: SharedService,
    private router: Router,
    private translate: TranslateService,
    private messagingService: MessagingService,) {
     this.congressId = this.storageService.read(StorageService.CONGRESS_ID)
     this.congress = this.storageService.read(StorageService.CONGRESS);
     this.user = this.storageService.read(StorageService.USER_KEY);
     this.configLandingPage = this.storageService.read(StorageService.CONFIGLANDINGPAGE);
     this.lang = this.translate.currentLang; 
     
  }

  ngOnInit(): void {
    this.sharedService.setTitle('Home');
    setTimeout(() => {
      this.user = this.storageService.read(StorageService.USER_KEY);
      if (!this.user) {
        if (this.congress && this.congress.config.mandatory_login_pwa == 1) {
          this.router.navigate(['/auth/login']);
        }
      }
    }, 2500);
    this.processingMainRoom();
    this.messagingService.receiveMessage();
    if (!this.congress) {     
      this.getCongressDetails();
    } else if (this.congress.congress_id.toString() != this.congressId) {
      this.getCongressDetails();
    }
    this.getPwaBtns();
    if (!this.configLandingPage) {
      this.getConfigLandingPage();
    } else if (this.configLandingPage.congress_id.toString() != this.congressId) {
      this.getConfigLandingPage();
    }

    if (this.configLandingPage && (new Date(this.configLandingPage.opening_date).getTime()) >= new Date().getTime()) {
      this.router.navigate([this.congressId + '/waiting-room']);
    }
    this.getMotPresident();
  }

  getCongressDetails() {
    this.busy = this.congressService.getCongressDetailsById(this.congressId).subscribe((data: Congress) => {
      this.congress = data;
      this.storageService.write(StorageService.CONGRESS, this.congress);
      setTimeout(() => {
     this.user = this.storageService.read(StorageService.USER_KEY);
      if (this.congress && this.congress.config.mandatory_login_pwa == 1 && !this.user) {
        this.router.navigate(['/auth/login']);
      }
    }, 2500);
    });
  }

  getCongressTime(congressDate) {
    return Utils.getTimeFromDate(congressDate);
  }

  getCongressDate(congressDate) {
    return Utils.convertDateToFrenchFormat(congressDate);
  }

  getValueTranslate(item, columnName) {
    this.lang = this.translate.currentLang;     
    return Utils.getValueTranslate(this.lang, item, columnName);
  }
  processingMainRoom() {
    this.messagingService.requestPermission();
    this.messagingService.currentToken.subscribe((value) => {
      if (value !== null) {
        this.body.source = 'pwa';
        this.body.userId = this.user.user_id;
        this.body.token = value;
        this.messagingService.saveToken(this.congressId, this.body).subscribe(
          (response) => {
            console.log(response); // pour le test et la validation
          }
        );
      }
    });
    this.messagingService.currentMessage.subscribe((message) => {
       if (message !== null && message.data.title === 'meeting') {
        this.messagingService.currentMessage.next(null);
     }
    });
  }
  
  getPwaBtns() {
    this.busy = this.congressService.getManagePwaBtns(parseInt(this.congressId)).subscribe((data) => {
      this.pwaBtns = data;
      for (let btn of this.pwaBtns) {
        if (btn.url) {
          btn.url = btn.url.replace('{congressId}', this.congressId);
          const isSubmissionIndex = btn.url.indexOf('isSubmission=true');
          if (isSubmissionIndex !== -1) {
            btn.isSubmissionParam = true;
            const queryStringIndex = btn.url.indexOf('?');
            if (queryStringIndex !== -1) {
              btn.url = btn.url.substring(0, queryStringIndex);
            }
          }
        }
      }
    });
  }

  getConfigLandingPage() {
    this.busy = this.congressService.getConfigLandingPageById(this.congressId).subscribe((data: any) => {
      if (data.config_landing_page) {
        this.configLandingPage = data.config_landing_page;
        this.storageService.write(StorageService.CONFIGLANDINGPAGE, this.configLandingPage);
      }
    });
  }

  getMotPresident() {
    this.busy = this.congressService.getMotPresident(parseInt(this.congressId)).subscribe((data: any) => {
      this.motPresident = data.data;
      if (this.motPresident.img_url)
        this.pathsLogo.push(this.filesUrl + this.motPresident.img_url);
        this.truncateMotPresident();
    });
  }

  truncateMotPresident() {
    if (this.motPresident.mot_president) {
      const words = this.motPresident.mot_president.split(' ');
      if (words.length > 23) {
        this.truncatedMot = words.slice(0, 23).join(' ');
        this.showExpandButton = true;
      } else {
        this.truncatedMot = this.motPresident.mot_president;
        this.showExpandButton = false;
      }
    }
  }

  expandContent() {
    this.expanded = true;
  }
  
  collapseContent() {
    this.expanded = false;
  }


}
