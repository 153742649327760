<form [formGroup]="multistepForm" class="multistep-form form-evauation-user container">
    <div *ngFor="let question of questionsAndResponses; let i = index" class="step" [class.active]="currentStep === i">
      <h3>{{ question.key }}</h3>
      <div *ngFor="let response of question.values">
        <div *ngIf="question.type.name === 'radio'">
          <input type="radio" [formControlName]="question.key" (change)="addResponse($event.target.checked,question.form_input_id, response.form_input_value_eval_id)" [value]="response.label_fr" id="{{response.label_fr + i}}">
          <label for="{{response.label_fr + i}}">{{ response.label_fr }}</label>
        </div>
        <div *ngIf="question.type.name === 'checklist'">
            <input type="checkbox"  class="form-check-input" (change)="addResponse($event.target.checked,question.form_input_id, response.form_input_value_eval_id)" [value]="response.form_input_value_eval_id" id="{{response.label_fr + i}}" >
            <label for="{{response.label_fr + i}}">{{ response.label_fr }}</label>
          </div>
      </div>
    </div>
    <div class="navigation-buttons">
      <button *ngIf="currentStep > 0" (click)="prevStep()" class="btn">Previous</button>
      <button *ngIf="currentStep < questionsAndResponses.length - 1" (click)="nextStep()" class="btn">Next</button>
      <button *ngIf="currentStep === questionsAndResponses.length - 1" (click)="submitForm()" type="submit" class="btn btn-submit">Submit</button>
    </div>
  </form>
  