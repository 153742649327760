import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Credentials } from '../models/credentials';
import { GenericService } from './generic.service';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericService {
  loggedUser: User;

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
    this.loggedUser = <User>storageService.read(StorageService.USER_KEY);
  }

  login(credentials: Credentials, congressId: string) {
    const url = environment.baseUrl + '/auth/login/user?congressId=' + congressId;
    return this.http.post<Credentials>(url, credentials).pipe(catchError(this.handleErrors));
  }

  getUserByQrCode(qrCode) {
    const url = environment.baseUrl + '/user-app/user-connect';
    return this.http.post(url, { qr_code: qrCode }).pipe(catchError(this.handleErrors));
  }
 
  getResponseUserInformations(userId: number,congressId: number, all: boolean = true) {
    const url = environment.baseUrl + "/user/congress/" + congressId + "/user-details/"+userId+`?all=${all}`;
    return this.http.get(url);
  }

  getAllUsersByCongress(congressId: string, page: number, perPage: number, search: string, filterBy: [] = null, isSelectCountry = null,selected_b2b = null) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/congress/" + congressId + `/listUsersPWA?page=${page}&perPage=${perPage}&search=${search}&filterBy=${filterBy}&isSelectCountry=${isSelectCountry}&selected_b2b=${selected_b2b}`;
    return this.http.get(url, { headers: headers });
  }

  addUserToNetwork(favId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/network/add";
    return this.http.post(url, { fav_id: favId }, { headers: headers });
  }

  getAllUserNetwork() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/network/list";
    return this.http.get(url, { headers: headers });
  }

  deleteUserNetwork(userNetworkId) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/network/delete/" + userNetworkId;
    return this.http.delete(url, { headers: headers });
  }

  getUserByEmail(email: string) {
    const url = environment.baseUrl + '/users/by-email/';
    const body = {email: email};
    return this.http.post<any>(url, body).pipe(catchError(this.handleErrors));
  }

  getUserById(userId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/users/' + userId + '/pwa';
    return this.http.get(url, { headers: headers });
  }

  getRandomUsersByCongress(congressId: string, countryId:string = '') {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/user/congress/" + congressId + `/random-users-pwa?country_id=${countryId}`;
    return this.http.get(url, { headers: headers });
  }

  clearCache() {
    const url = environment.baseUrl + "/user/congress/clear-cache";
    return this.http.get(url);
  }
  
  modifUserInfo(user: User): Observable<User> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/user/edit/profile';
    return this.http.put<User>(url, user, {
      headers: headers,
    });
  }

  checkMeetingRights(congressId: number, meetingId: number) {
    const url = environment.baseUrl + '/congress/' + congressId + '/' + meetingId + '/checkMeetingRights/';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  getLoggedUser(token: string) {
    const url = environment.baseUrl + '/user/me';
    const headers = this.headers.set('Authorization', 'Bearer ' + token);
    return this.http.get(url, {headers: headers});
  }

  registerUserToCongress(congressId: number, user: User) {
    const url = environment.baseUrl + "/user/congress/" + congressId + '/register';
    return this.http.post(url,user);
  }

  refreshToken() {
    return this.http.get(environment.baseUrl + "/auth/refreshtoken");
  }

   logout() {
    return this.http.get(environment.baseUrl + "/auth/logout");
  }
  downloadParticipant(badgeId: string, user: User, naming?: string) {
    const url = environment.badgeServiceUrl + "/badge/generateParticipant";
    let name;
    name = user.first_name[0].toUpperCase() + user.first_name.slice(1) + " " + user.last_name.toUpperCase();
    
    return this.http.post(url,
      {
        badgeIdGenerator: badgeId,
        participant: {
          name: name,
          qrCode: user.qr_code
        }
      },
      {
        headers: this.headers,
        responseType: 'blob'
      });
  }

  getUserByEmailAndQrCode(qrCode, email) {
    const url = environment.baseUrl + '/users/user-by-qr-code?email=' + email + '&qr_code=' + qrCode;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  getUserWithAttestation(congress_id,user_id) {
    const url = environment.baseUrl + '/users/' + congress_id + '/user-attestation/' + user_id;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }
}
