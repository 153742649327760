import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FAQ } from '../shared/models/faq';
import { StorageService } from '../shared/services/storage.service';
import { FAQService } from '../shared/services/faq.service';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-faq-congress',
  templateUrl: './faq-congress.component.html',
  styleUrls: ['./faq-congress.component.css']
})
export class FaqCongressComponent implements OnInit {

  faq: FAQ[] = [];
  busy: Subscription;
  congressId: number;

  constructor(private storageService: StorageService,
    private faqService: FAQService, private sharedService: SharedService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngOnInit(): void {
    this.sharedService.setTitle('FAQ');
    this.getAllFaqBycongress();
  }

  getAllFaqBycongress() {
    this.busy = this.faqService.getAllFaqBycongress(this.congressId).subscribe((data: FAQ[]) => {
      this.faq = data;
    })

  }

}
