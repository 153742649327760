import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Submission } from 'src/app/shared/models/Submission';
import { SubmissionService } from 'src/app/shared/services/submission.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from 'src/app/shared/services/shared.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-abstracts-pdf',
  templateUrl: './abstracts-pdf.component.html',
  styleUrls: ['./abstracts-pdf.component.css']
})
export class AbstractsPdfComponent implements OnInit {
  pathResources = environment.filesUrl;
  submission: Submission = new Submission();
  busy: Subscription;
  submissionId: number;
  selectedUrl: string;
  message: string = '';
  isLike: boolean = false;
  congressId: number;

  constructor(private submissionService: SubmissionService, private route: ActivatedRoute, private sharedService: SharedService, private router: Router,private storageService: StorageService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
   }

  ngOnInit(): void {
    this.sharedService.setTitle('AbstractPdf');
    this.submissionId = parseInt(this.route.snapshot.paramMap.get('sbmissionId'), 0);
    this.getAcceptedSubmissionsByCongress();
  }

  getAcceptedSubmissionsByCongress() {
    this.busy = this.submissionService.getPublicSubmissionById(this.submissionId).subscribe((data) => {
      this.submission = data;
      this.selectedUrl = this.pathResources + this.submission?.resources[0]?.path;
    });
  }

  addlikepdf(submissionId: number) {
    this.busy = this.submissionService.setPdfLike(submissionId).subscribe((data: any) => {
      this.submission = data.response;
    });
  }

  redirectToQuiz(){
    this.router.navigate([this.congressId + '/quiz-abstract'], { queryParams: { 'qrCode': this.submission.qr_code } });
  }
}
