import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from '../shared/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared/services/shared.service';
import { User } from '../shared/models/user';
import { CongressService } from '../shared/services/congress.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-congress-evaluation',
  templateUrl: './user-congress-evaluation.component.html',
  styleUrls: ['./user-congress-evaluation.component.css']
})
export class UserCongressEvaluationComponent implements OnInit {
  configLang: string = "FR";
  busy: Subscription;
  congressId: number;
  user: User = new User();
  multistepForm: FormGroup;
  questionsAndResponses: any[];
  currentStep: number = 0;
  selectedResponses: { form_input_id: number | null, selectedResponse: number }[] = [];

  constructor(
    private route: ActivatedRoute, private formBuilder: FormBuilder,
    private router: Router,
    private storageService: StorageService,
    private translate: TranslateService,
    private sharedService: SharedService, private congressService: CongressService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.multistepForm = this.formBuilder.group({});
    this.questionsAndResponses = [];
    this.getEvaluationByCongress();
  }

  ngOnInit(): void {
    this.sharedService.setTitle('PageEvaluation');
  }

  getEvaluationByCongress() {
    this.congressService.getEvaluationByCongress(this.congressId).subscribe((data: any) => {
      this.questionsAndResponses = data;
      this.setupForm();
    });
  }

  setupForm() {
    this.multistepForm = this.formBuilder.group({});
    this.questionsAndResponses.forEach(question => {
      let formControl;
      if (question.type.name === 'radio') {
        formControl = this.formBuilder.control('', Validators.required);
      } else if (question.type.name === 'checklist') {
        const checklistArray = question.values.map(() => this.formBuilder.control(false));
        formControl = this.formBuilder.array(checklistArray);
      }
      this.multistepForm.addControl(question.key, formControl);
    });
  }

  nextStep() {
    if (this.selectedResponses[this.currentStep]) {
      this.currentStep++;
    } else {
      window.alert(this.translate.instant('SelectOneResponse'));
      return;
    }

  }

  prevStep() {
    this.currentStep--;
  }

  submitForm() {

    if (this.selectedResponses[this.currentStep]) {
      const userId = this.user.user_id;
      const EvaluationData = {
        userId: userId,
        selectedResponses: this.selectedResponses
      };
      this.busy = this.congressService.setEvaluationUser(EvaluationData, this.congressId).subscribe((data: any) => {
        window.alert(this.translate.instant('ThankYou'));
        this.router.navigate([this.congressId + '/home']);
      });
    }
    else {
      window.alert(this.translate.instant('SelectOneResponse'));
      return;
    }
  }


  addResponse(checked: boolean, questionId: number, responseId: number) {
    const selectedResponseIndex = this.selectedResponses.findIndex(response => response.selectedResponse === responseId);
    if (checked && selectedResponseIndex === -1) {
      this.selectedResponses.push({ form_input_id: questionId, selectedResponse: responseId });
    } else if (!checked && selectedResponseIndex !== -1) {
      this.selectedResponses.splice(selectedResponseIndex, 1);
    }
  }
}
