import * as moment from 'moment';
import { User } from './models/user';
declare var jQuery: any;
export class Utils {

  static getAvailableDates(congress) {

    let today = new Date(moment().format('YYYY-MM-DD'));
    let dateEnd = new Date(moment(congress.end_date).format('YYYY-MM-DD'));
    let dateStart = new Date(moment(congress.start_date).format('YYYY-MM-DD'));
    let dates = []
    if (today > dateEnd) {
      return dates;
    }
    if (today < dateStart) {
      dates.push(moment(dateStart).format('YYYY-MM-DD'));
    }
    else { dates.push(moment(today).format('YYYY-MM-DD')); }
    let i = 0;
    let myDate;
    while (dates[i] != moment(congress.end_date).format('YYYY-MM-DD')) {
      myDate = today.setDate(today.getDate() + 1)
      dates.push(moment((new Date(myDate))).format('YYYY-MM-DD'))
      i++;
    }
    return dates;
  }

  static concatenation(string1, string2) {
    let string = string1 + " " + string2
    return string;
  }

  static validateTimePeriode(begin, end) {
    var beginningTime = moment(begin, 'h:mma');
    var endTime = moment(end, 'h:mma');
    return beginningTime.isAfter(endTime);
  }

  static getDurationTimes(duree, pause, startTime, endTime) {
    let times = []; 
   
    let time_start = moment(startTime, "HH:mm").format("HH:mm");
    let time_end = moment(endTime, "HH:mm").format("HH:mm");
    if (this.validateTimePeriode(startTime, endTime) || (startTime == endTime)){
      time_start = moment('00:00', "HH:mm").format("HH:mm");
      time_end = moment('23:30', "HH:mm").format("HH:mm");
    }
    times.push(time_start);
    let new_time: any;
    let i = 0;
    while (times[i] < time_end) {
      new_time = pause == 0 ? moment(times[i], "HH:mm").add(duree, "minutes").format("HH:mm"): moment(times[i], "HH:mm").add(pause + duree, "minutes").format("HH:mm");
      if (new_time < time_end && moment(new_time, "HH:mm").add(duree, "minutes").format("HH:mm") <=time_end) {
        times.push(new_time);
      }
      i++;
    }
    return times;
  }

  static convertTimeStampToDate(timeStamp) {
    let date: string;
    return date = moment(timeStamp).calendar();
  }

  static addDudation(timeStart, duration) {
    let time = moment(timeStart, 'HH:mm').add(duration, 'minutes').format("HH:mm");
    return time;
  }
 
    public static getUserFirstLetters(user: User) {
        return user?.first_name?.charAt(0).toUpperCase() + user?.last_name?.charAt(0).toUpperCase();
    }

    public static getUserName (user) {
      return this.capitalizeFirstLetters(user.first_name) + ' ' + user.last_name.toUpperCase();
    }

    public static withChoices(response) {
        return response.form_input.form_input_type_id == 6 || response.form_input.form_input_type_id == 7 || response.form_input.form_input_type_id == 8 || response.form_input.form_input_type_id == 9;
    }

    public static changeMode() {
        let theme = document.getElementById('theme-mode');
        if (theme.classList.contains('theme-dark')) {
            theme.classList.add('theme-light')
            theme.classList.remove('theme-dark')
            document.body.classList.remove('theme-dark');
        } else {
            theme.classList.remove('theme-light')
            theme.classList.add('theme-dark')
            document.body.classList.add('theme-dark');
        }
    }

    public static getRandomPage(totlPages: number) { 
      return Math.floor(Math.random() * totlPages ) +1;
    }

    static getPrivateChatName(senderId, receiverId) {
      return senderId < receiverId ?  'chat_' + senderId + '_' + receiverId : 'chat_' + receiverId + '_' + senderId;
    }

    static getRoomName(congressId, meetingId = null) {
      const code = meetingId ? congressId + '_m_' + meetingId : congressId;
      return 'eventizer_room_' + code;
    }
    static getRoomAccess(congressId: string, accessId: number = null) {
      const code = accessId ? congressId + '' + accessId : congressId;
      return 'eventizer_room_' + code;
    }

    static dateFormater(date){
      return moment(date).format('YYYY-MM-DDTHH');
    }
    
    public static getRandomString(n: number = 10, pos: number = 0) {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < n; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text + pos;
    }

    public static addZero(myDate) { 
      return (myDate < 10) ? '0' + myDate : myDate; 
    }

    public static convertDateToFrenchFormat(someDateFromt) {
      if (!someDateFromt) return '';
      var arr = someDateFromt.toString().split(/[- :]/);
      return (arr[2] + '/' + arr[1] + '/' + arr[0]);
    }

    public static formatDateMonthString(accessDate)
    {
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
  
      const date = new Date(accessDate);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
  
      const formattedDate = `${day} ${months[monthIndex]} ${year}`;
      return formattedDate;
    }

    public static getTimeFromDate(someDateFromt) {
      if (!someDateFromt) return '';
      var arr = someDateFromt.toString().split(/[- :]/);
      return (arr[3] + ':' + arr[4]);
    }

    public static timeSince(date) {
      date = new Date(date);
      var seconds = Math.floor((new Date().getTime() - date.getTime() ) / 1000);
      var intervalType;
      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = 'year';
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = 'month';
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = 'day';
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = "hour";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = "minute";
              } else {
                interval = seconds;
                intervalType = "second";
              }
            }
          }
        }
      }
      if (interval > 1 || interval === 0) {
        intervalType += 's';
      }
      return interval + ' ' + intervalType;
    }
  
    static getValueTranslate (lang: string, item, columnName: string) {
      if (!item) return '';
      const engColumn = columnName + '_en';
      const arColumn = columnName + '_ar';
      if ((lang == 'fr' && item[columnName])|| (lang == 'en' && !item[engColumn] || (lang == 'ar' && !item[arColumn]))) {
        return item[columnName];
      } else if (lang == 'en' && item[engColumn]) { 
        return item[engColumn];
      } else if (lang == 'ar' && item[arColumn]) {
        return item[arColumn];
      }
   }

   static getHourByDate (date) {
     return moment(date).format('HH:mm');
   }

   static getDate (date) {
     return moment(date).format('YYYY-MM-DD');
   }    

   static nombreJourEvent(date1, date2) {
    var eventStartTime = new Date(date1);
    var eventEndTime = new Date(date2);
    var day = 1000 * 60 * 60 * 24;
    var dateOfEvent = [];
    var diff = (eventEndTime.getTime() - eventStartTime.getTime()) / day;

    for (let i = 0; i <= diff ; i++) {
      var xx = eventStartTime.getTime() + day * i;
      var yy = new Date(xx);
      dateOfEvent.push(yy)
    }
    return dateOfEvent;
  }
  
   public static capitalizeFirstLetters(str) {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  }

  static getDateTime (date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  static isEmail(email: string) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
  }
  static RemoveAccents(s) {
    const i = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖŐòóôõöőÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜŰùúûüűÑñŠšŸÿýŽž'.split('');
    const o = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUUuuuuuNnSsYyyZz'.split('');
    const map = {};
    i.forEach(function (el, idx) {
      map[el] = o[idx];
    });
    return s.replace(/[^A-Za-z0-9]/g, function (ch) {
      return map[ch] || ch;
    });
  }

  static getAttestationByAccessAndPriv(attestations: any[], privilegeId: any) {
    for (let i = 0; i < attestations.length; i++) {
      if (attestations[i].privilege_id === privilegeId) {
        return attestations[i].attestation_generator_id;
      }
    }
    return null;
  }

}