import { UserNetwork } from './userNetwork';
import { Resource } from "./resource";
import { Responses } from "./response";


export class User {
  user_id: number;
  last_name: string = "";
  first_name: string = "";
  email: string = "";
  code: string;
  mobile: string;
  country_id: string;
  gender: number;
  profile_img: Resource;
  responses: Responses[] = []
  network_member: UserNetwork[];
  qr_code: string;
  user_congresses: User_Congress[] = [];
  country: any;
  resource_id: number;
  privilege_id: number;
  accesses:any[];
}

export class User_Congress {
  user_congress_id: number;
  congress_id: number;
  user_id: number;
  chat_info: string;
  privilege_id: number;
  privilege: Privilege;
  first_visit_pwa: number;
  selected_b2b: number;
}
export class Privilege {
  privilege_id: number;
  name: string;
}
