import { UserService } from 'src/app/shared/services/user.service';
import { User_Congress } from './../shared/models/user';
import { User } from 'src/app/shared/models/user';
import { StorageService } from './../shared/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ChatService } from '../shared/services/chat.service';
import { Utils } from '../shared/utils';
import { MeetingService } from '../shared/services/meeting.service';
import { CongressService } from '../shared/services/congress.service';
import { Subscription } from 'rxjs';
import { Congress } from '../shared/models/congress';
import { PwaMenu } from '../shared/models/pwamenu';
import { TranslateService } from '@ngx-translate/core';

declare var jQuery: any;

@Component({
  selector: 'app-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.css']
})
export class BottomNavigationComponent implements OnInit {
  congressId: string;
  isHistory: boolean = false;
  isUser: boolean;
  roomName: string;
  user: User = new User();
  haveUnreadMessages: boolean = false;
  numberWaitingMeeting: number;
  isCalendar: boolean = false;
  userCongresss: User_Congress = new User_Congress();
  busy: Subscription;
  congress: Congress = new Congress();
  pwamenus: PwaMenu[];
  lang: string;

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private router: Router,
    private chatService: ChatService,
    private meetingService: MeetingService,
    private congressService: CongressService,
    private userService: UserService,
    private translate: TranslateService
  ) {

    this.congressId = this.route.snapshot.paramMap.get('congressId');
    this.storageService.write(StorageService.CONGRESS_ID, this.congressId);
    this.congress = this.storageService.read(StorageService.CONGRESS);
    this.isUser = this.storageService.read(StorageService.USER_KEY) ? true : false;
    this.isHistory = this.router.url.includes('chat-history');
    this.isCalendar = this.router.url.includes('calendar');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isHistory = event.url.includes('chat-history');
        this.isCalendar = event.url.includes('calendar');
      }
      if (event instanceof NavigationEnd) {
        if (event.url.includes('calendar')) {
          this.numberWaitingMeeting = 0;
        }
      }
    });
    this.user = this.storageService.read(StorageService.USER_KEY);
    if (this.user) {
      this.userCongresss = this.user.user_congresses.find(userCongress => userCongress.congress_id.toString() == this.congressId);
    }

  }

  ngOnInit(): void {
    if (!this.congress) {
      this.getCongress();
    }
    if (this.isUser) {
      this.roomName = Utils.getRoomName(this.congressId);
      this.getAllHistory();
    } 
    this.getNumbreWaitingMeeting();
    this.getAllPwaMenus();
  }

  letScan() {
    if (!this.storageService.isExist(StorageService.USER_KEY)) {
      this.router.navigateByUrl('/auth/qr-code');
    }
  }

  getAllHistory() {
    this.chatService.getALLHistory(this.roomName).subscribe((actions: any[]) => {
      this.filterHistory(actions);
    });
  }

  filterHistory(actions) {
    this.haveUnreadMessages = false;
    actions.forEach(action => {
      if (Object.values(action.payload.val())[0]['user_id'] == this.user.user_id && Object.values(action.payload.val())[0]['unreadMessages'] != 0) {
        this.haveUnreadMessages = true;
        return;
      }
    });
  }

  getNumbreWaitingMeeting() {
    this.meetingService.getNumberWaitingMeeting(this.congressId).subscribe((number: number) => {
      this.numberWaitingMeeting = number;
    });
  }

  qrCodeScan() {
    this.router.navigate([this.congressId + '/qr-code'], { queryParams: { 'scan-for-list-user-meeting': true } });
  }

  scanQrCodeForQuiz() {
    this.router.navigate([this.congressId + '/qr-code'], { queryParams: { 'isSubmission': true } });
  }

  getCongress() {
    this.busy = this.congressService.getCongressDetailsById(this.congressId.toString()).subscribe((data: Congress) => {
      this.congress = data;
    });
  }

  getUrlNetworking() {
    if (this.congress?.config?.show_fix_networking == 1 && this.congress?.config?.show_free_networking == 0)
      return "/" + this.congressId + "/B2B"
    if (this.congress?.config?.show_fix_networking == 0 && this.congress?.config?.show_free_networking == 1)
      return "/" + this.congressId + "/search-B2B"
    else
      return "/" + this.congressId + "/networking"
  }

  getAllPwaMenus() {
    this.busy = this.congressService.getAllPwamenuByCongress(parseInt(this.congressId)).subscribe(
      (pwamenus: PwaMenu[]) => {
        this.pwamenus = pwamenus;
      }
    )
  }

  getValueTranslate(item, columnName) {
    this.lang = this.translate.currentLang;     
    return Utils.getValueTranslate(this.lang, item, columnName);
  }
}


