<div class="page-content header-clear-medium">
	<div class="theme-light" data-highlight="highlight-red" data-gradient="body-default" [ngBusy]="busy">
	  <div>
		  <div class="page-content header-clear-medium" style="padding-top: 5px !important;">
  
			  <div class="card card-style">
				  <img
					  src="{{congress?.config?.banner ? filesUrl + getValueTranslate( congress?.config ,'banner'):'../../assets/images/pictures/18.jpg'}}"
					  class="rounded-sm img-fluid">
				  <div class="content mt-0">
					<div class="title-event-home">
						<strong class="color-theme font-20 d-block mb-n2 mt-3">{{getValueTranslate(congress, 'name')}}</strong>
					</div>
					<div class="container-full mot-president" *ngIf="motPresident">
						<div>
							<strong class="color-theme font-20 d-block mb-n2 mt-3" [innerHTML]=" motPresident?.titre_message"></strong>
						</div>
						<div class="row">
							<div class="col-3 img-president"><img [src]="pathsLogo[0]" /></div>
							<div class="col-9 text-president" [innerHTML]="expanded ? motPresident?.mot_president : truncatedMot"></div>
                            

						</div>
						<button class="show-more" *ngIf="showExpandButton && !expanded" (click)="expandContent()">{{'ShowMore' | translate}}</button>
                        <button class="show-less" *ngIf="expanded" (click)="collapseContent()">{{'ShowLess' | translate}}</button>
					
					</div>
					<div _ngcontent-eta-c21="" class="clearfix mb-4"></div>
					<div _ngcontent-eta-c21="" class="divider divider-president"></div>  
					<div class="container">
						<div class="row">
							<div class="splide__track col-4" *ngFor="let pwaBtn of pwaBtns;">
								<div class="splide__list">
									<div class="splide__slide" *ngIf="pwaBtn?.visible == 1">
										<a href="javascript:void(0)" class="mx-1">
											<div *ngIf="pwaBtn.isSubmissionParam" class="card card-style  me-2 my-0 mt-n3"
												[routerLink]="pwaBtn?.url" [queryParams]="{isSubmission: pwaBtn.isSubmissionParam}">
												<i style="text-align: center;" class="{{pwaBtn?.icon}} color-highlight fa-2x mt-4"></i>
												<h5 class="mb-2 mt-2" style="text-align: center;">
													{{ getValueTranslate(pwaBtn, 'name')}}
												</h5>
											</div>
											<div *ngIf="!pwaBtn.isSubmissionParam" class="card card-style  me-2 my-0 mt-n3"
												[routerLink]="pwaBtn?.url">
												<i style="text-align: center;" class="{{pwaBtn?.icon}} color-highlight fa-2x mt-2"></i>
												<h5 class="mb-2 mt-2" style="text-align: center;">
													{{ getValueTranslate(pwaBtn, 'name')}}
												</h5>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					  <div class="clearfix mb-4"></div> 
					  <div class="divider"></div>
					  <div *ngIf="configLandingPage?.show_hide_event_information">
					  <h3 class="event-info"> {{ 'EventInfo' | translate }} </h3>
					  <p class="mb-4">
						  {{getValueTranslate(congress, 'description')}}
					  </p>  
					  <div class="row ">
						  <div class="col">
							  <div class="d-flex">
								  <div class="align-self-center">
									  <i class="fa fa-calendar color-teal-dark font-23 me-3 text-center width-20"></i>
								  </div>
								  <div class="align-self-center">
									  <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50"> {{'StartDate' | translate}} </span>
									  <strong class="d-block font-12 pb-1 color-theme"> {{getCongressDate(congress?.start_date)}}
									  </strong>
								  </div>
							  </div>
						  </div>
						  <div class="col">
							  <div class="d-flex">
								  <div class="align-self-center">
									  <i class="fa fa-clock color-red-dark font-23 me-3 text-center width-20"></i>
								  </div>
								  <div class="align-self-center">
									  <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50">{{'StartTime' | translate }}</span>
									  <strong class="d-block font-12 pb-1 color-theme">{{getCongressTime(congress?.start_date)}}</strong>
								  </div>
							  </div>
						  </div>
						  </div>
						  <div class="row">
						  <div class="col">
							  <div class="d-flex">
								  <div class="align-self-center">
									  <i class="fa fa-calendar color-teal-dark font-23 me-3 text-center width-20"></i>
								  </div>
								  <div class="align-self-center">
									  <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50"> {{'EndDate' | translate}} </span>
									  <strong class="d-block font-12 pb-1 color-theme"> {{getCongressDate(congress?.end_date)}}
									  </strong>
								  </div>
							  </div>
						  </div>
						  <div class="col">
							  <div class="d-flex">
								  <div class="align-self-center">
									  <i class="fa fa-clock color-red-dark font-23 me-3 text-center width-20"></i>
								  </div>
								  <div class="align-self-center">
									  <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50">{{'EndTime' | translate }}</span>
									  <strong class="d-block font-12 pb-1 color-theme">{{getCongressTime(congress?.end_date)}}</strong>
								  </div>
							  </div>
						  </div>
					  </div>
					</div>
				  </div>
			  </div>
		  </div>
	  </div>
	</div>
  </div>