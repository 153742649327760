import { PwaMenu } from "./pwamenu";




export class ConfigLandignPage {
    congress_id: number;
    f_menu: PwaMenu[] = [];
    waiting_title: string;
    waiting_desription: string;
    opening_date: Date;   
    waiting_title_ar:string;
    waiting_description_ar:string;
    waiting_banner: string; 
    waiting_color: string;
    waiting_opacity: string;
    show_hide_event_information:number;
    
}
