import { Access } from "./access";
import { FormInput } from "./formInput";
import { MeetingDates } from "./MeetingDates";
import { User } from "./user";
import { ConfigSubmission } from "./configSubmission";



export class Congress {
    congress_id: number;
    title: string;
    config: Config;
    accesss: Access[]=[];
    themes: string[];
    startDateSubmission: Date;
    startDateEvaluation: Date;
    startDate: Date;
    speciality: string;
    id: string;
    endDateSubmission: Date;
    endDateEvaluation: Date;
    endDate: Date;
    picture: File;
    name: string;
    start_date: Date;
    end_date: Date;
    price: number;
    description: string;
    congress_type_id: number;
    location: Location;
    config_submission: ConfigSubmission;
    path_abstract_book: string;
    meeting_dates: MeetingDates[];
    name_en: string;
    name_ar: string;
    description_en: string;
    description_ar: string;
}
export class Config {
    config_congress_id: number;
    logo: string;
    banner: string;
    banner_en: string;
    banner_ar: string;
    free: number = 0;
    is_online: number;
    has_payment: boolean = false;
    program_link: string;
    congress_id: number;
    access_system: string = 'Workshop';
    status: number;
    meeting_duration: number;
    pause_duration: number;
    show_in_chat: string;
    networking_fixe_msg: string;
    networking_fixe_msg_en: string;
    networking_libre_msg: string;
    networking_libre_msg_en: string;
    show_free_networking: number;
    agora_primary_background: string;
    agora_secondary_background: string;
    show_fix_networking: number;
    search_countries: number;
    mandatory_login_pwa: number;
    show_interactions: number;
    show_meesage_field: number;
    default_country: string;
    attestation_pwa:number;
    faq_pwa_btn:string;

}
