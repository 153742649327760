<a routerLink="/{{abstract?.submission_id}}/abstract-pdf" class="no-effect card card-style mb-2 collapsed">
        <div class="content">
            <div class="d-flex mb-n1">
                <div>
                    <h3 class="title-submission">{{ abstract.title }}</h3>
                    <p *ngFor="let authors of abstract.authors" class="opacity-80 font-10 mt-n2 author">{{ authors.first_name }} {{
                        authors.last_name }}</p>
                </div>
                <div class="ms-auto text-center type-abstract">
                    {{ abstract.type }}<br>
                    <span *ngIf="abstract.code" class="code-abstract">
                        {{ abstract.code }}  
                    </span>
                </div>
            </div>
        </div>
    </a>