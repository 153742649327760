import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SharedService } from '../shared/services/shared.service';
import { ChangeDetectorRef } from '@angular/core';
import { Utils } from '../shared/utils';
import { StorageService } from '../shared/services/storage.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { environment } from 'src/environments/environment';
import { ChatService } from '../shared/services/chat.service';
import { User } from '../shared/models/user';
import { UserService } from '../shared/services/user.service';
import { Subscription } from 'rxjs';
import { FirebaseUser } from '../shared/models/quiz';
import { Congress } from '../shared/models/congress';
import { CongressService } from '../shared/services/congress.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent implements OnInit {
  title: string = '';
  isUser: boolean;
  isLoginPage: boolean = false;
  lang: string;
  congressId: number;
  chatName: string;
  userSender: User = new User();
  user: any;
  userRecieverId: number;
  userReciever: User = new User();
  busy: Subscription;
  receiverFirstLetters: string;
  roomName: string;
  haveUnreadMessages: boolean = false;
  usersData = [];
  usersKeys: string[] = [];
  firebaseUser: FirebaseUser = new FirebaseUser();
  userKey: string;
  congress:Congress = new Congress();


  constructor(private location: Location,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private storageService: StorageService,
    private router: Router,
    private translate: TranslateService,
    private chatService: ChatService,
    private route: ActivatedRoute,
    private userService: UserService,
    private congressService: CongressService,
    @Inject(DOCUMENT) private document: Document) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    this.isUser = this.storageService.read(StorageService.USER_KEY) ? true : false;
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.isLoginPage = this.router.url.includes('login');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLoginPage = event.url.includes('login');
      }
    });
    this.userSender = this.storageService.read(StorageService.USER_KEY);

    this.route.params.subscribe(params => {
      this.userRecieverId = params.userId;
      this.congressId = this.congressId == undefined ? params.congressId:this.congressId;
    });
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {

    this.roomName = Utils.getRoomName(this.congressId);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });
    this.sharedService.title.subscribe(title => {
      this.title = title;
      this.cdRef.detectChanges()
    });

    if (this.user) {
      this.getAllHistory();
      this.getUsersData();
    }
    this.getCongress();

  }

  goBackLastPage() {
    this.location.back();
  }

  tunrOnDarkMode() {
    Utils.changeMode();
  }

  goToAction() {
    if(!this.user && !this.isUser){
      this.router.navigate(['/auth/login']);
    }
    if (this.user) {
      let sidebar = document.getElementById('menu-sidebar-left-1');
      sidebar.classList.add('menu-active');
      let menuHider = document.querySelectorAll('.menu-hider')[0];
      menuHider.classList.add('menu-active');
      menuHider.addEventListener("pointerdown", function () {
        menuHider.classList.remove('menu-active');
        sidebar.classList.remove('menu-active');
      });
    } else {
      window.location.reload();
    }
  }

  saveLanguage(event) {
    this.translate.use(event.target.value);
    this.storageService.write('lang', event.target.value);
    this.changeLangage(event.target.value);
  }

  changeLangage(lang: string) {
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.changeCssFile(lang);
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
    let cssLink = this.document.getElementsByTagName("link")[3] as HTMLLinkElement;
    let bundleName = lang === "ar" ? "arabicStyle.css" : "englishStyle.css";
    if (cssLink) {
      cssLink.href = bundleName;
    } else {
      let newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
  OnSubmit() {
    if (!this.isUser) {
      this.storageService.write(StorageService.URL, this.congressId + '/qr-code');
      this.storageService.write(StorageService.URLPARAMS, { 'isSubmission': true });
      this.router.navigate(['/auth/login']);
    } else {
      this.router.navigate([this.congressId + '/qr-code'], { queryParams: { isSubmission: true } });
    }
  }

  getUserById() {
    this.busy = this.userService.getUserById(this.user.user_id).subscribe((data: User) => {
      this.userReciever = data;
      this.receiverFirstLetters = Utils.getUserFirstLetters(this.userReciever);
    });
  }


  getAllHistory() {
    this.chatService.getALLHistory(this.roomName).subscribe((actions: any[]) => {
      this.filterHistory(actions);
    });
  }

  filterHistory(actions) {

    this.haveUnreadMessages = false;
    actions.forEach(action => {
      if (Object.values(action.payload.val())[0]['user_id'] == this.user.user_id && Object.values(action.payload.val())[0]['unreadMessages'] != 0) {
        this.haveUnreadMessages = true;
        return;
      }
    });
  }

  getUsersData() {
    if (this.user) {
      this.chatService.getUsersData(this.roomName).subscribe((data: any) => {
        this.usersData = data;
        this.usersKeys = data ? Object.keys(data).map(val => {
          return val;
        }) : [];
        const isUser = this.findUser(this.user.user_id);
        if (isUser !== undefined) {
          this.firebaseUser = isUser.user;
          this.userKey = isUser.key;
        } else {
          this.addUserData();
        }
      });
    }    
  }

  addUserData() {
    if (this.user && this.user.user_congresses && this.user.user_congresses.length > 0) {
      this.chatService.addUsersData(this.user, this.roomName, 0, this.user.user_congresses[0].privilege_id);
    } else {
      this.chatService.addUsersData(this.user, this.roomName, 0, 3);
    }
  }

  findUser(userId) {
    let user;
    for (const userKey of this.usersKeys) {
      if (this.usersData[userKey].userId && this.usersData[userKey].userId === userId) {
        const userData = this.usersData[userKey];
        user = {
          user: userData,
          key: userKey
        };
        break;
      }
    }
    return user;
  }

  getCongress() {
      this.busy = this.congressService.getCongressDetailsById(this.congressId.toString()).subscribe((data: Congress) => {
        this.congress = data;
        this.storageService.write(StorageService.CONGRESS, this.congress);
        }); 
  }

}
