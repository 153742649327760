<div class="container accordion-faq" [ngBusy]="busy">
    <div id="accordion" class="py-5">
      <div *ngFor="let item of faq; let i = index" class="card border-0 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
        <div class="card-header p-0 border-0" [id]="'heading-' + i">
          <button class="btn btn-link accordion-title border-0 collapsed" data-toggle="collapse" [attr.data-target]="'#collapse-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-' + i">
            <i class="fas fa-plus text-center d-flex align-items-center justify-content-center h-100"></i>
            {{ item.question }}
          </button>
        </div>
        <div [id]="'collapse-' + i" class="collapse" [attr.aria-labelledby]="'heading-' + i" data-parent="#accordion">
            <div class="card-body accordion-body" [innerHTML]="item.response"></div>
        </div>
      </div>
    </div>
  </div>
  