<div class="pdf-container" [ngBusy]="busy">
    <pdf-viewer [src]="selectedUrl" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
        [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
        [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height: 900px; margin-top: 45px;min-height: 100vh;"></pdf-viewer>
</div>
<div [ngBusy]="busy">
    <div *ngIf="submission?.submission_likes?.length == 0" class="btn-group btn-group-toggle love-btn" style="left: 148px !important">
        <label *ngIf="submission?.quiz_selection==1" class="" ngbButtonLabel (click)="redirectToQuiz()">
            <img src="../../../assets/images/quiz.png" style="width: 65px;">
        </label>
        <label *ngIf="submission?.congress?.config_submission?.show_like_btn == 1" class="" ngbButtonLabel>
            <img src="../../../assets/images/dark_like.png" style="width: 77px;" (click)="addlikepdf(submissionId)">
        </label>
    </div>
    <div *ngIf="submission?.submission_likes?.length > 0"
        class="btn-group btn-group-toggle love-btn" style="left: 148px !important">
        <label *ngIf="submission?.quiz_selection==1" class="" ngbButtonLabel (click)="redirectToQuiz()">
            <img src="../../../assets/images/quiz.png" style="width: 65px;">
        </label>
        <label *ngIf="submission?.congress?.config_submission?.show_like_btn == 1" class="" ngbButtonLabel>
            <img src="../../../assets/images/like.png" style="width: 65px;" (click)="addlikepdf(submissionId)">
        </label>
    </div>
</div>