import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GenericService } from "./generic.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class FAQService extends GenericService {
  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAllFaqBycongress(congressId: number){
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + '/congress/'+ congressId + '/faqPwa';
    return this.http.get(url, { headers: headers });
  }
}