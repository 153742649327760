import {Congress} from "./congress";
import {CommunicationType} from './CommunicationType';
import { QuizAbstract } from "./QuizAbstract";
import { Themes } from "./theme";

export class Submission {

  submission_id: number;
  title: string;
  code: string;
  type: string;
  prez_type: string;
  description: string;
  global_note: number;
  status: number;
  congress_id: number;
  congress: Congress;
  created_at: Date;
  limit_date: string;
  communication_type_id: number;
  communication_type: CommunicationType;
  communicationType: CommunicationType;
  key_words:string;
  theme_id : number;
  qr_code : string;
  quiz_abstract :QuizAbstract[] = [];
  theme:Themes;
  authors: Authors;
  resources: Ressource [] = [];
  nb_like:number;
  submission_likes: any;
  quiz_selection : number;
}
export class Authors {
  author_id: number;
  first_name: string;
  last_name: string;
  email: string;
  rank: number;
  submission_id: number;
  service_id: number;
  etablissement_id: number;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}

export class Ressource {
  submission_id: string;
  resource_submission_id: string;
  path: string;
  resource_id: string;
}

