import { StorageService } from './shared/services/storage.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {GoogleAnalyticsService} from './shared/services/google-analytics.service';
import {environment} from '../environments/environment';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { DNSService } from './shared/services/dns.service';
import { PageService } from './shared/services/page.service';
import { UserService } from './shared/services/user.service';
import { User } from './shared/models/user';
import { Title } from '@angular/platform-browser';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'participant-pwa';
  currentLang: string;
  user: User = new User();
  congressId: number;

  constructor(public translate: TranslateService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    private strorageService: StorageService,
    private dnsService: DNSService,
    private pageService: PageService,
    public titleService: Title,
    private route : ActivatedRoute,
    private userService: UserService) {
    if (this.route.snapshot.paramMap.get('congressId')) {
      this.congressId = parseInt(this.route.snapshot.paramMap.get('congressId'));
    }
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.userService.getUserByEmailAndQrCode(params['token'], params['email']).subscribe((data: any) => {
          this.user = data.user;
          this.userService.loggedUser = data.user;
          this.strorageService.write(StorageService.USER_KEY, data.user);
          this.strorageService.write(StorageService.USER_TOKEN_KEY, data.token);
          if (this.congressId) {
            this.router.navigate([this.congressId + '/home']);
          } else if (params['congressId']) {
            this.router.navigate([params['congressId'] + '/home']);
          }
        });
      }
    });
    translate.addLangs(['en', 'fr', 'ar']);
    this.currentLang = this.strorageService.read(StorageService.LANGUAGE);
    translate.setDefaultLang(this.currentLang ? this.currentLang : 'fr');
    const browserLang = translate.getBrowserLang();
    translate.use(this.currentLang ? this.currentLang : browserLang.match(/en|fr|ar/) ? browserLang : 'en');
    GoogleAnalyticsService.loadGoogleAnalytics(environment.googleAnalyticsId);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsId,
          {
            'page_path': event.urlAfterRedirects
          }
        );
        GoogleAnalyticsService.eventEmitter(event.urlAfterRedirects);
      }
    });

    this.changeLangage(this.translate.currentLang);

    /* custom redirect */
    const url = window.location.href;
    if (!url.includes(environment.url)) {
      this.dnsService.getDNSByUrl(url).subscribe((data: any) => {
        if (data.redirect) {
          const newUrl = data.redirect.replace(':congressId', data.congress_id);
          // extract param from the url
          const queryParams = {};
          const queryString = newUrl.split('?')[1];
          if (queryString) {
            queryString.split('&').forEach(param => {
              const parts = param.split('=');
              queryParams[parts[0]] = decodeURIComponent(parts[1]);
            });
          }
          // redirect to the url after insering params if they exist
          queryString ? this.router.navigate([newUrl.slice(0, newUrl.indexOf('?'))], { queryParams: queryParams }) : this.router.navigate([newUrl]);
        }
      });
  
    }

    this.pageService.getPageByUrl(url).subscribe((data: any) => {
      if ((data.title) && (data.type == 3)) { 
        this.titleService.setTitle(data.title); 
      } else {
        this.titleService.setTitle("Participant PWA"); 
      }
     } );
  }

  ngOnInit(): void {
  
  }

  changeLangage(lang: string) {
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.changeCssFile(lang);
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
    let cssLink = this.document.getElementsByTagName("link")[3] as HTMLLinkElement;
    let bundleName = lang === "ar" ? "arabicStyle.css" : "englishStyle.css";
    if (cssLink) {
      cssLink.href = bundleName;
    } else {
      let newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
}
