import { User } from 'src/app/shared/models/user';
import { QuizAbstract } from 'src/app/shared/models/QuizAbstract';
import { MeetingService } from 'src/app/shared/services/meeting.service';
import { SubmissionService } from 'src/app/shared/services/submission.service';
import { SharedService } from './../../shared/services/shared.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { UserService } from '../../shared/services/user.service';
import { StorageService } from '../../shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";

declare var jQuery: any;

@Component({
  selector: 'app-qr-code-scan',
  templateUrl: './qr-code-scan.component.html',
  styleUrls: ['./qr-code-scan.component.css']
})
export class QrCodeScanComponent implements OnInit {

  @ViewChild('scanner', { static: true }) scanner: ZXingScannerComponent;

  hasCameras = false;
  hasPermission: boolean;
  questions: QuizAbstract[] = [];
  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;
  busy: Subscription;
  congressId: number;
  message: string;
  scanForListUserMeeting: boolean = false;
  meetingId: number = null;  
  isOrganizer: string;
  isSubmission: string;
  url : string;
  user: User;

  constructor(private userService: UserService,
    private storageService: StorageService,
    private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private meetingService: MeetingService,
    private submissionService: SubmissionService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.route.queryParams.subscribe(params => {
      if (params['scan-for-list-user-meeting']) {
        this.scanForListUserMeeting = true;
      }
      if (params['meetingId']) {
        this.meetingId = params['meetingId'];
        this.isOrganizer = params['isOrganizer'];
        this.url = params['url'].replace(/\+/g,'%20');
      }
      if (params['isSubmission']){
        this.isSubmission = params['isSubmission'];
      }
    })
    }

  ngOnInit() {
    if (!this.user) {
      this.router.navigate(['/auth/login']);
    }
    this.sharedService.setTitle('ScanQRCode');
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasCameras = true;
      for (const device of devices) {
        if (/back|rear|environment/gi.test(device.label)) {
          this.selectedDevice = device;
          break;
        }
      }
    });

    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.error('An error has occurred when trying to enumerate your video-stream-enabled devices.');
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
    });
    
  }

  handleQrCodeResult(qrCode: string) {
    if (this.scanForListUserMeeting) {
      this.router.navigate([this.congressId + '/calendar'], { queryParams: { qr_code: qrCode } });
    } else if (this.meetingId) {
      this.isOrganizer == 'false' ?  this.makeParticipantPresentByQrCode(qrCode): this.makeOrganizerPresentByQrCode(qrCode);
    } else if (this.isSubmission == 'true'){
      this.getQuizAbstract(qrCode);
    } else {
      this.userService.getUserByQrCode(qrCode)
        .subscribe(
          (data: any) => {
            this.storageService.write(StorageService.USER_KEY, data.user);
            this.storageService.write(StorageService.USER_TOKEN_KEY, data.token);
            this.router.navigate([this.congressId + '/home']);
          },
          (error) => {
            this.message = 'TryAgain';
            jQuery('#errorMessage').modal('show');
          }
        );
    }
  }

  makeOrganizerPresentByQrCode(qrCode) {
    this.meetingService.makeOrganizerPresentByQrCode(this.meetingId, qrCode, this.url).subscribe(data => {
      this.router.navigate([this.congressId + '/calendar']);
    }, err => {
      if (err.status == 400) {
        this.message = 'UserNotFound';
      } else if (err.status == 401) {
        this.message = 'requiredQrCode';
      } else if (err.status == 402) {
        this.message = 'TableNoFound';
      } else if (err.status == 403) {
        this.message = 'NoMeetingTable';
      }
      jQuery('#errorMessage').modal('show');
    });
  }

  makeParticipantPresentByQrCode(qrCode) {
    this.meetingService.makeParticipantPresentByQrCode(this.meetingId, qrCode, this.url).subscribe(data => {
      this.router.navigate([this.congressId + '/calendar']);
    }, err => {
      if (err.status == 400) {
        this.message = 'UserNotFound';
      } else if (err.status == 401) {
        this.message = 'requiredQrCode';
      } else if (err.status == 402) {
        this.message = 'TableNoFound';
      } else if (err.status == 403) {
        this.message = 'NoMeetingTable';
      }
      jQuery('#errorMessage').modal('show');
    });
  }

  getQuizAbstract(qrCode) {
    this.submissionService.getSubmissionByQrCode(qrCode).subscribe((data: any) => {
      if (data.length == 0) {
        this.message = 'NoQuiz';
        jQuery('#errorMessage').modal('show');
      } else {
        if (data.submission_id) {
          this.router.navigate([this.congressId + '/quiz-abstract'], { queryParams: { 'qrCode': qrCode } });
        } else {
          if (data.quiz_id) {
            this.router.navigate([this.congressId + '/quiz-abstract'], { queryParams: { 'qrCode': qrCode } });
          } else {
            this.router.navigate([this.congressId + '/quiz-event'], { queryParams: { 'qrCode': qrCode } });
          }
        }
      }
    });
  }

}
