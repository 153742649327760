<div [ngBusy]="busy" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
    <div class="search-box search-header bg-theme card-style me-3 ms-3" style="margin-bottom: 16px;">
        <i class="fa fa-search"></i>
        <input *ngIf="!withUsers && !fromNetworking" type="text" id="search-input" [(ngModel)]="search" (ngModelChange)="enableRemove()" class="border-0"
            placeholder="{{ 'SearchFor' | translate }}" data-search>
        <input *ngIf="withUsers && !fromNetworking" type="text" id="search-users-stand" [(ngModel)]="search" (ngModelChange)="enableRemove()" class="border-0"
            placeholder="{{ 'SearchFor' | translate }}" data-search>
        <input *ngIf="fromNetworking" type="text" id="search-users-networking" [(ngModel)]="search" (ngModelChange)="enableRemove()" class="border-0"
            placeholder="{{ 'SearchFor' | translate }}" data-search>
        <a class="clear-search disabled mt-0" (click)="removeSearch()"><i class="fa fa-times color-red-dark"></i></a>
    </div>
    <div *ngIf="countries.length !=0" class="input-style has-borders no-icon card-style me-3 ms-3">
        <select class="select-countries" id="form5" (change)="FilterCountry($event)" *ngIf="searchCountries">
            <option *ngFor="let country of countries" [value]="country.alpha3code">{{country.name_fr}}</option>
        </select>      
    </div>
    <div *ngIf="formInput.length !=0" >        
        <ng-select style="margin: 16px;"
        [placeholder]="hasPublicLabel() ? getValueTranslate('public_label') : getValueTranslate('label')"  [items]="filterValues" bindLabel="value" bindValue="form_input_value_id" [multiple]="true" id="select"  [(ngModel)]="filterKeys"
        (change)="filterBy($event)" ></ng-select>
    </div>
    <div *ngIf="!withUsers && gTags.length > 1" class="input-style has-borders no-icon card-style me-3 ms-3">
        <select id="form5" (change)="filterStagsByGStagsId($event)">
            <option  [value]="null" selected >  {{ 'GTag' | translate}}  </option>         
            <option *ngFor="let gTag of gTags" [value]="gTag.gstag_id">{{gTag.label}}</option>
        </select>
        <span><i class="fa fa-chevron-down"></i></span>
    </div>
    <div  *ngIf="!withUsers" class="input-style has-borders no-icon card-style me-3 ms-3">
        <select id="form5" name="stag" (change)="getTagId($event)">
        <option [value]="null" selected >{{ 'Tag' | translate}} </option>            
        <option *ngFor="let stag of selectedTags" [value]="stag.stag_id">{{stag.label}}</option>
        </select>
        <span><i class="fa fa-chevron-down"></i></span>
    </div>
    <app-default-users *ngIf="showNothingFound && withUsers && (!searching || search == '' && filterKeys.length == 0)" [typeData]="'user'" [selectedCountry]="isSelectCountry" [showMeetingButton]="showMeetingButton"></app-default-users>
    <app-default-users *ngIf="withUsers && (!searching || search == '' && filterKeys.length == 0) && !fromNetworking" [typeData]="'stand'" [showMeetingButton]="showMeetingButton"></app-default-users>
    <div class="search-stand-results disabled-search-list">
        <div>
            <div *ngFor="let stand of stands" class="card" style="height: 120px;margin: 0px 16px 30px 16px; border-radius: 15px;"
                [ngStyle]="{'background-image': stand?.organization?.banner != null ? 'url(' + filesUrl + stand?.organization?.banner + ')' : 'url( \'../../../assets/images/pictures/18.jpg \')' }">
                <app-item-stand [stand]="stand"></app-item-stand>
            </div>
        </div>
    </div>    
    <div *ngIf="withUsers && fromNetworking && userCongresss.selected_b2b ==1" class="users-list splide single-slider slider-no-arrows slider-no-dots splide--loop splide--ltr splide--draggable"
        id="users-list" style="visibility: visible;">
        <div class="splide__track">
            <div class="splide__list" style="width: 100%;" >
                <div class="splide__slide mx-3 mb-3 splide__slide--clone" *ngFor="let user of B2bUsers">
                    <div class="px-3 pt-3 list-users">
                        <app-item-user [showMeetingButton]="showMeetingButton" [user]="user"></app-item-user>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
    <div *ngIf="withUsers && !fromNetworking" class="users-list splide single-slider slider-no-arrows slider-no-dots splide--loop splide--ltr splide--draggable"
        id="users-list" style="visibility: visible;">
        <div class="splide__track">
            <div class="splide__list" style="width: 100%;" >
                <div class="splide__slide mx-3 mb-3 splide__slide--clone" *ngFor="let user of users">
                    <div class="px-3 pt-3 list-users">
                        <app-item-user [showMeetingButton]="showMeetingButton" [user]="user"></app-item-user>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>

</div>
<app-nothing-found *ngIf="showNothingFound"></app-nothing-found>

